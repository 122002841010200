import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"
import { Helmet } from "react-helmet"
import favicon64 from "../images/favicon64.png"
import favicon32 from "../images/favicon32.png"
import favicon16 from "../images/favicon16.png"
import social from "../images/social.png"

const Content = styled.div`
  width: calc(100% - 230px);
  display: flex;
  flex-direction: column;
  margin: 60px 0 0 0;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    width: 100%;
    margin: 30px 0 0 0;
  }
`

const Title = styled.h1`
  font-size: 48px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 20px 0;

  @media (max-width: 700px) {
    font-size: 36px;
  }
`

const Description = styled.p`
  width: 600px;
  font-size: 16px;
  color: black;
  margin: 0 0 20px 0;
  font-family: "franklinGothic";
  line-height: 1.3;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const OneLine = styled.p`
  width: 600px;
  font-size: 16px;
  color: black;
  margin: 0 0 10px 0;
  font-family: "franklinGothic";
  line-height: 1.3;

  @media (max-width: 1000px) {
    width: 100%;
  }

  @media (max-width: 700px) {
    font-size: 14px;
  }
`

const AddressStart = styled(OneLine)`
  margin: 20px 0 5px 0;
`

const Address = styled(OneLine)`
  margin: 0 0 5px 0;
`

const LastLine = styled(OneLine)`
  margin: 20px 0 0 0;
`

const SubTitle = styled.h3`
  font-size: 36px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 10px 0 20px 0;

  @media (max-width: 700px) {
    font-size: 24px;
  }
`

const PastIssueTitle = styled.h6`
  font-size: 18px;
  color: black;
  font-family: "MinionPro";
  font-weight: 400;
  margin: 0 0 10px 0;

  @media (max-width: 700px) {
    margin: 30px 0 10px 0;
  }
`

const PastIssue = styled.div`
  width: 200px;
  height: 275px;
  background-color: #c4c4c4;
`

const EmailLink = styled.a`
  text-decoration: underline;
  line-height: 1.3;
  color: black;
`

const About = () => {
  return (
    <>
      <Helmet
        link={[
          {
            rel: "icon",
            type: "image/png",
            sizes: "16x16",
            href: `${favicon16}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "32x32",
            href: `${favicon32}`,
          },
          {
            rel: "icon",
            type: "image/png",
            sizes: "64x64",
            href: `${favicon64}`,
          },
        ]}
      >
        <title>About - The Cannon</title>
        <meta property="og:title" content={`The Cannon`} />
        <meta
          property="og:description"
          content="The Cannon is the official (serious) newspaper of the University of Toronto Engineering Society. Established in 1978, it serves the undergraduate students of the Faculty of Applied Science and Engineering."
        />
        <meta property="og:image" content={social} />
        <meta property="og:image:url" content={social} />
      </Helmet>
      <Content>
        <Title>ABOUT</Title>
        <Description>
          The Cannon is the official (serious) newspaper of the University of
          Toronto Engineering Society. Established in 1978, it serves the
          undergraduate students of the Faculty of Applied Science and
          Engineering.
        </Description>
        <Description>
          The Cannon proudly shares articles about the Skule™ community, opinion
          pieces, student life and more, with the goal of connecting engineering
          students.
        </Description>
        <SubTitle>Contribute</SubTitle>
        <Description>
          Suggestions and articles are always welcome - no previous experience
          necessary! Email{" "}
          <EmailLink href="mailto:cannon@g.skule.ca">
            cannon@g.skule.ca
          </EmailLink>{" "}
          to find out more
        </Description>
        <SubTitle>Contact</SubTitle>
        <OneLine>
          Facebook:{" "}
          <EmailLink
            href="https://www.facebook.com/cannon.news/"
            target="_blank"
          >
            cannon.news
          </EmailLink>
        </OneLine>
        <OneLine>
          Instagram:{" "}
          <EmailLink
            href="https://www.instagram.com/cannon.news/?hl=en"
            target="_blank"
          >
            @cannon.news
          </EmailLink>
        </OneLine>
        <AddressStart>The Cannon</AddressStart>
        <Address>
          10 King’s College Road Sandford Fleming Building Room B740
        </Address>
        <Address>Toronto, ON</Address>
        <Address>M5S 3G4</Address>
        <LastLine>
          Advertising and subscription information is available from the
          Engineering Society at 416-978-2917.
        </LastLine>
      </Content>
    </>
  )
}

export default About
